import ApiService from './ApiService'
import { CognitoUser, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from 'UserPool';
import { v4 as uuidv4 } from 'uuid';

export async function apiSignIn ({Username, Password}) {

    return new Promise((resolve, reject) => {

      const user = new CognitoUser({
          Username,
          Pool: UserPool,
      });


      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {

         onSuccess: (result) => {
           resolve(result);
         },
         onFailure: (err) => {
           reject(err);
         },
         newPasswordRequired: (data) => {
           resolve(data);
         },

      });

    });
}

export async function apiSignUp (data, callback) {
    const { email, password, given_name, family_name, tenantId } = data;
    const username = email;

    const attributeList = [
        new CognitoUserAttribute({
            Name: 'email',
            Value: email,
        }),
        new CognitoUserAttribute({
            Name: 'given_name',
            Value: given_name,
        }),
        new CognitoUserAttribute({
            Name: 'family_name',
            Value: family_name,
        }),
        new CognitoUserAttribute({
            Name: 'custom:tenantId',
            Value: tenantId,
        })

    ];

    return UserPool.signUp(username, password, attributeList, null, callback);

}

export async function apiTenantSignUp (data, callback) {
    const { email, password, full_name, tenant_phone, tenant_name, tenant_id } = data;
    const username = email;
    const nameArray = full_name.split(" ");
    let given_name = ''
    let family_name = ''

    if(nameArray.length == 1){
        given_name = nameArray[0]
    }

    if(nameArray.length >= 2){
        given_name = nameArray[0]
        family_name = nameArray[1]
    }

    const attributeList = [
        new CognitoUserAttribute({
            Name: 'email',
            Value: email,
        }),
        new CognitoUserAttribute({
            Name: 'given_name',
            Value: given_name,
        }),
        new CognitoUserAttribute({
            Name: 'family_name',
            Value: family_name,
        }),
        new CognitoUserAttribute({
            Name: 'custom:tenantPhone',
            Value: tenant_phone,
        }),
        new CognitoUserAttribute({
            Name: 'custom:tenantName',
            Value: tenant_name,
        }),
        new CognitoUserAttribute({
            Name: 'custom:tenantId',
            Value: uuidv4(),
        }),
        new CognitoUserAttribute({
            Name: 'custom:isAdmin',
            Value: 'true',
        }),

    ];

    return UserPool.signUp(username, password, attributeList, null, callback);

}

export async function apiInvitedUserSignUp (data, callback) {
    const { email, password, full_name, tenant_name, tenant_id } = data;
    const username = email;
    const nameArray = full_name.split(" ");
    let given_name = ''
    let family_name = ''

    if(nameArray.length == 1){
        given_name = nameArray[0]
    }

    if(nameArray.length >= 2){
        given_name = nameArray[0]
        family_name = nameArray[1]
    }

    const attributeList = [
        new CognitoUserAttribute({
            Name: 'email',
            Value: email,
        }),
        new CognitoUserAttribute({
            Name: 'given_name',
            Value: given_name,
        }),
        new CognitoUserAttribute({
            Name: 'family_name',
            Value: family_name,
        }),
        new CognitoUserAttribute({
            Name: 'custom:tenantName',
            Value: tenant_name,
        }),
        new CognitoUserAttribute({
            Name: 'custom:tenantId',
            Value: tenant_id,
        }),
        new CognitoUserAttribute({
            Name: 'custom:isAdmin',
            Value: 'false',
        }),

    ];
    return UserPool.signUp(username, password, attributeList, null, callback);
}

export async function apiSignOut (data) {
//    return ApiService.fetchData({
//        url: '/sign-out',
//        method: 'post',
//        data
//    })
    return UserPool.getCurrentUser().signout();
}

export async function apiForgotPassword (data) {
    const {Username} = data.email;

    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: data.email,
        Pool: UserPool,
      });

      user.forgotPassword({
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (data) => {
          resolve(data);
        },
      });
    });
}

export async function apiResetPassword (data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}


export async function apiGetInvite (inviteId) {
    return ApiService.fetchPublicData({
        url: '/pub/invite/'+inviteId,
        method: 'get'
    })
}