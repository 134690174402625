import axios from 'axios'
import BaseService from './BaseService'

const publicService = axios.create({
    timeout: 60000,
    baseURL: window.API_BASE_URL
})

const ApiService = {

    fetchData(param) {
        return new Promise((resolve, reject) => {

            BaseService(param).then(response => {
                resolve(response)
            }).catch(errors => {
                reject(errors)
            })

        })
    },

     fetchPublicData(param) {
            return new Promise((resolve, reject) => {

                publicService(param).then(response => {
                    resolve(response)
                }).catch(errors => {
                    reject(errors)
                })

            })
     },



}

export default ApiService