import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignOutSuccess } from '../store/auth/sessionSlice'

import { CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from 'UserPool';

const unauthorizedCode = [401]

const BaseService = axios.create({
    timeout: 60000,
    baseURL: window.API_BASE_URL
})

BaseService.interceptors.request.use(async(config) => {


    let accessToken;
    const cognitoUser = UserPool.getCurrentUser();
    await cognitoUser.getSession((err, session) => {
        accessToken = session.idToken.jwtToken;
    });

    if (accessToken) {
        config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`
    }

    return config
}, error => {
    return Promise.reject(error)
})

BaseService.interceptors.response.use(
    response => {
        return response
    },
    error => {

        const { response } = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }

        return Promise.reject(error)
    }
)

export default BaseService